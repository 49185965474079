








































































import Vue from "vue";
import Footer from "../components/footer/Footer.vue"

export default Vue.extend({
  name: "Services",
  components: {
    Footer
  },
  methods:{
    goto(name: string){
      this.$router.push({name: name})
    }
  }
});
